import Contact from "../../components/contact/Contact";
import FeatureImgThree from "../../components/features/FeatureImgThree";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import Price from "../../components/prices/Price";
import BrandCarousel from "../../components/testimonial/BrandCarousel";
import HeroThree from "./HeroThree";

export default function HomeThree() {
  return (
    <Layout>
      <Navbar />
      <HeroThree />
      <FeatureImgThree />
      <BrandCarousel />
      <Price />
      <Contact bgColor />
      <Footer space />
    </Layout>
  );
}
