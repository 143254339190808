export default function Price({ hasBg }) {
  return (
    <>
      <div className="overflow-hidden">
        <section
          id="pricing"
          className={`package-section ${
            hasBg ? "gray-light-bg" : "background-shape-right"
          } ptb-100`}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <h2>
                  Pricing & Plans
                  </h2>
                  <p className="lead">
                    Choose from our distinctive pricing model tailored to your scale of operation for 6 months support on a package you select.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md">
                <div className="card text-center single-pricing-pack p-5">
                  <h5 className="mb-2">Startups</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/basic.svg"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <li>Cloud Architecture Design</li>
                      <li>Cloud Cost Reduction Strategies</li>
                      <li>Infrastructure As Code Adoption</li>
                      <li>Kubernetes Adoption</li>
                      <li>Automation and Release</li>
                      <li>Cloud Governance and Security Compliance</li>
                      <li>Tech Stack Modernization</li>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        $<span className="price font-weight-bolder">20,000</span>
                      </div>
                    </div>
                    <a href="#/" className="btn outline-btn" target="_blank">
                      Purchase now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md">
                <div className="card text-center popular-price single-pricing-pack p-5">
                  <h5 className="mb-2">Medium Scale Businesses</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/standard.svg"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <li>Cloud Architecture Review</li>
                      <li>Cloud Cost Reduction Strategies</li>
                      <li>Observability Core</li>
                      <li>Release and Automation</li>
                      <li>Cloud Governance and Security Compliance</li>
                      <li>Tech Docs Review</li>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        $<span className="price font-weight-bolder">50,000</span>
                      </div>
                    </div>
                    <a href="#/" className="btn solid-btn" target="_blank">
                      Purchase now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md">
                <div className="card text-center single-pricing-pack p-5">
                  <h5 className="mb-2">Large Scale Enterprise</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/unlimited.svg"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <li>Observability and Telemetry Stacks</li>
                      <li>GitOps</li>
                      <li>Service Mesh</li>
                      <li>Self-Hosted CI/CD Runners</li>
                      <li>Release Automation</li>
                      <li>Data Infrastructure</li>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        $<span className="price font-weight-bolder">100,000</span>
                      </div>
                    </div>
                    <a href="#/" className="btn outline-btn" target="_blank">
                      Purchase now
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 text-center">
              <p className="mb-2">
                If you need custom services or Need more?
                <a href="#/" className="color-secondary">
                  {" "}
                  Contact us{" "}
                </a>
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
